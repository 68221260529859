@import "./main";

body,
html {
  padding: 0;
  margin: 0;
  font-family: "Arial";
}

.footer-powered-container {
  font-size: 10px;
  color: $main-color;
  text-transform: none;
  a,
  button {
    font-size: 10px;
    color: $main-color;
    text-decoration: none;
    text-transform: none;
  }
  .footer-powered {
    padding-right: 5px;
  }
}

.makeStyles-liIcon {
  fill: $backgroundGrey !important;
  color: $backgroundGrey;
  width: 32px !important;
  height: 23px !important;
  margin-left: 12px;
  margin-right: 12px;
}

.makeStyles-liIcon-RateTable {
  fill: #ed6c02 !important;
  color: $backgroundGrey;
  width: 32px !important;
  height: 23px !important;
  margin-left: 12px;
  margin-right: 12px;
  filter: invert(48%) sepia(47%) saturate(3747%) hue-rotate(4deg)
    brightness(97%) contrast(98%);
}
