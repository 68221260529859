/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::-webkit-scrollbar{
  width: 6px;
}
::-webkit-scrollbar-track{
  background: #e7ebee;
}
::-webkit-scrollbar-thumb{
  background: #bb8bb9;
  border-radius: 10px;
}
/* Horizontal scrollbar */
::-webkit-scrollbar {
  height: 6px; /* Adjust the height for horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: #e7ebee;
}

::-webkit-scrollbar-thumb {
  background: #bb8bb9;
  border-radius: 10px;
}

[type='text']:focus, input:where(:not([type])):focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
  outline: none!important;
  border-color: transparent!important;
  box-shadow: none!important;
}
@media print {
.print-hide {
  display: none !important;
}
}