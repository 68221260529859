/* BarcodeScanner.css */

.barcode-scanner-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .video-container {
    position: relative;
  }
  
  .animated-rectangle {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    border: 2px dashed #0f0; /* Change color for the scanning state */
    animation: pulse 1.5s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .result-container {
    margin-top: 10px;
    padding: 8px;
    border: 1px solid #ccc;
  }
  
  .start-button {
    margin-top: 10px;
  }
  